.two-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 25px;

  &__column {
    width: 42%;
    min-width: 250px;
    margin: 15px;
  }

  &__title {
    font-weight: 700;
    font-size: 23px;
  }
}

.home-video-container {
  border-radius: 20px;
  padding: 20px;
  background-color: black;
  min-height: 350px;
}

.class-news-details {
  & h3 {
    padding-top: -8px;
    margin-top: -10px;
  }
}
@media (max-width: 900px) {
  .two-columns {
    &__column {
      width: 80%;
    }
  }
}
