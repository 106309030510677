.drawer-container {
  padding: 24px;
  height: 100%;
  color: #6f4600;
  display: flex;
  flex-direction: column;
}

.nav-link {
  text-decoration: none;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: #6f4600;
}

@media (max-width: 700px) {
  .drawer-container {
    & h1 {
      padding-top: 30px;
    }
  }
}
