.contact-container {
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  color: #6f4600;
  text-align: center;

  & a {
    text-decoration: none;
    max-width: 80%;
  }
}

.form-input {
  margin: 8px 0;
  width: 100%;
}

.contact-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 700px) {
  .contact-container {
    width: 100%;
    padding: 10px 0px;
  }
}
