.hero-container {
  background-color: #eee9df;
  color: #6f4600;
  height: 328px;
  display: flex;
  justify-content: center;
  margin-bottom: 44px;

  & img {
    align-self: flex-end;
    max-width: 40%;
    max-height: 135%;
    z-index: 1000;
  }
}

.hero-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  &__text {
    font-size: 4rem;
    // margin-bottom: -40px;
    text-align: right;
    line-height: 4.1rem;
  }
}

@media (max-width: 1100px) {
  .hero-container {
    height: 30vh;
  }
  .hero-title {
    &__text {
      font-size: 3rem;
      line-height: 3.2rem;
    }
  }
}

@media (max-width: 700px) {
  .hero-container {
    height: 25vh;
    & img {
      max-width: 60%;
    }
  }
  .hero-title {
    &__text {
      font-size: 2rem;
      line-height: 2.3rem;
    }
  }
}
