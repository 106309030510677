.mediapage-container {
  padding: 35px;
}
.contents-container {
  // display: flex;
  // flex-wrap: wrap;
}
iframe {
  border-radius: 20px;
}

.videos-container {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
}
.video-card {
  height: 350px;
  width: 30%;
  min-width: 200px;
  margin: 25px;
}



@media (min-width: 701px) and (max-width: 1550px) {
  .videos-container {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .video-card {
    height: 230px;
    width: 45%;
    min-width: 200px;
    margin: 15px;
  }
}

@media (max-width: 700px) {
  .mediapage-container {
    padding: 10px;
  }
  .contents-container {
    flex-direction: column;
  }
  .videos-container {
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  .video-card {
    width: 100%;
    min-width: 0;
    margin: 10px;
  }
}
